<template>
  <b-card class="p-1 full-screen container">

    <iframe
      :src="file_source"
      width="100%"
      class="full-screen"
      frameborder="0"
    />
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      file_source: null,
    }
  },
  async created() {
    const fileURL = await this.$generateDownloadUrl('media/e2i_loa.pdf')

    this.file_source = fileURL
  },
}
</script>

<style>
.full-screen {
  height: 100vh;
}
</style>
